import { default as React, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../compontents/Layout/Layout';
import {
    ctaNumber,
    ctaText,
    fluidImg,
    landing_text_container,
    landing_text_inner_wrapper,
} from './speedtomarket.module.scss';

type IndexPageProps = {
    location: any;
};

type HsbtpCreateArgumentType = {
    region: string;
    portalId: string;
    formId: string;
    target: string;
};

type HbsptType = {
    forms: {
        create: (options: HsbtpCreateArgumentType) => void;
    };
};

declare const hbspt: HbsptType;

const SpeedToMarketPage = ({ location }: IndexPageProps) => {
    const TARGET_ID = 'hubspot-target';

    // Src: https://stackoverflow.com/questions/34424845/adding-script-tag-to-react-jsx#answer-34425083
    useEffect(() => {
        // First check if the script already exists on the dom
        // by searching for an id
        const id = 'hubspot-form-script';
        if (document.getElementById(id) === null) {
            const script = document.createElement('script');
            script.setAttribute('src', 'https://js.hsforms.net/forms/embed/v2.js');
            script.setAttribute('id', id);
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('charset', 'utf-8');

            // Append it to the head

            document.head.appendChild(script);

            // now wait for it to load...
            script.onload = () => {
                const t = hbspt.forms.create({
                    region: 'na1',
                    portalId: '22505045',
                    formId: '24f5d8e3-4f89-4252-8aa1-86131a68f9c8',
                    target: `#${TARGET_ID}`,
                });

                // Ignore this as it may be unncessary

                // //@ts-ignore
                // const _hsq = (window._hsq = window._hsq ?? []);

                // // Use this to identify visitors and contacts
                // // Visitor. General term for any visitor to the site
                // // whether they have a hubspot account or not
                // // Contact. Visitor who has a hubspot account
                // // Identified by unique email address

                // handleAddTrackingToOtherPage(_hsq, '/');
                // handleAddTrackingToOtherPage(_hsq, '/speedtomarket');
            };
        }
    }, []);

    return (
        <Layout location={location}>
            <div data-testid="landingcontainer">
                <Helmet>
                    {/* TODO: Fill this text */}
                    <title>Free Speed Market Guide</title>
                </Helmet>

                {/*begin header*/}
                <div className={'landing-header row center'}>
                    <div className={'col-md-3 sm-hidden'}>
                        <img
                            className={'img-fluid'}
                            style={{maxHeight:'200px'}}
                            src="../images/landing-pages/squarehook-diamond-white-270.png"></img>
                    </div>
                    <div className={'col-md-9 my-auto'}>
                        <h2>Speed to Market Guide</h2>
                        <p>
                            Download this guide to Learn about automating your workflow, so you can cut costs and get to
                            market faster.
                        </p>
                    </div>
                </div>
                {/*end header*/}

                {/*sign up form*/}
                <div className={[landing_text_container, "row"].join(" ")}>
                    <div className={'col-md-6 center'}>
                        <img className={'img-fluid'} src="../images/landing-pages/speed_to_market_document.jpg"></img>
                    </div>
                    <div className={'col-md-6'}>
                        <h3 className={'center'}>GET THE DOWNLOAD TODAY</h3>
                        <div id={TARGET_ID}></div>
                    </div>
                </div>
                {/*end sign up form*/}

                {/*Struggle Described*/}
                <div className="container d-flex flex-direction-row p-2">
                    <div className="row justify-content-center align-items-center mb-5">
                        <div className="col-lg-4 col-md-6">
                            <h2>Blockers</h2>
                            <p>
                                When I speak to IT administrators, they often tell me they have internal blockers, like
                                a lack of time and resources. They know that there’s got to be better processes and
                                software solutions that increase productivity and affect bottom-line revenue, but they
                                don’t have time to look for outside help.
                            </p>
                        </div>
                        <div className="col-lg-8 col-md-5 p-2">
                            <img
                                src="../../images/developers1.jpg"
                                className={`${fluidImg} img-fluid`}
                                alt="Developer seated at work station with multiple screens"
                            />
                        </div>
                    </div>
                </div>
                {/*end struggle*/}

                {/*Solution Described*/}
                <div className="container d-flex flex-direction-row p-2">
                    <div className="row justify-content-center align-items-center mb-5">
                        <div className="col-lg-6 col-md-4 p-2">
                            <img
                                src="/images/landing-pages/cloudhosting-gears.png"
                                className={`${fluidImg} img-fluid`}
                                alt="Developer seated at work station with multiple screens"
                            />
                        </div>
                        <div className="col-lg-6 col-md-8">
                            <h2>Automate to get more done</h2>
                            <p>
                                Using automated processes with DevOps, you can have shorter development sprints and
                                faster feedback loops. You can free up people for more important work. You can
                                experience smoother workflows and get to market faster with continuous delivery.
                            </p>
                        </div>
                    </div>
                </div>
                {/*end solution*/}

                {/*explanitory section*/}
                <div className={landing_text_inner_wrapper}>
                    <h2 className={'center'} style={{ fontSize: '3rem' }}>
                        SquareHook can help
                    </h2>
                    <p>
                        At SquareHook, we know you want to be <strong>doers, producers,</strong>
                        the kind of people who <strong>get things done</strong>. Current manual processes are slowing
                        you down and holding you back, which feels restrictive and unproductive. We’ve seen it time and
                        time again from all kinds of companies and we understand the frustration. That’s why we
                        specialize in DevOps to help streamline your processes.
                    </p>
                    <p style={{ fontWeight: 'bold' }}>Here’s how it works</p>
                    <ol className={'mb-5'} style={{ fontSize: '1.25rem' }}>
                        <li>We’ll have an initial chat to identify where we could be the most use.</li>
                        <li>You’ll receive a proposal for a plan and see how DevOps can help you.</li>
                        <li>
                            We’ll implement your new automated processes to provide worry-free training on how to use
                            them.
                        </li>
                    </ol>
                    <div className={'center mb-5'}>
                        <p className={ctaText}>
                            <strong>So give us a call,</strong> so you can stop wasting time and money, and start
                            enjoying the benefits of a more automated workflow.
                        </p>
                        <p className={ctaNumber}>801-309-1959</p>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export default SpeedToMarketPage;
